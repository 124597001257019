<template>
<div class="tw-flex tw-w-full tw-flex-col lg:tw-flex-row tw-px-0 tw-mt-10">
  <div class="tw-flex tw-flex-col tw-w-full lg:tw-w-8/12">
    <div class="tw-flex tw-w-full lg:tw-flex-row tw-flex-col lg:tw-justify-between">
      <icon-and-text :width="$vuetify.breakpoint.smAndDown ? '100%' : '24%'" :icon="applicant" :title="totalApplicants.toString()" desc="Applicants"  />
      <icon-and-text :width="$vuetify.breakpoint.smAndDown ? '100%' : '24%'" :icon="accepted" :title="totalAcceptedApplicants.toString()" desc="Accepted"  />
      <icon-and-text :width="$vuetify.breakpoint.smAndDown ? '100%' : '24%'" :icon="coaches" :title="totalInstructors.toString()" desc="Coaches"  />
      <icon-and-text :width="$vuetify.breakpoint.smAndDown ? '100%' : '24%'" :icon="volunteers" :title="totalVolunteers.toString()" desc="Volunteer Shaperons"  />
    </div>
    <div class="tw-w-full tw-py-8">
      <popularity-chart />
    </div>
    <div class="tw-w-full tw-bg-white">
     <analytic-chart :width="$vuetify.breakpoint.smAndDown ?'100%' : '47%'" />
    </div>
  </div>
  <v-col sm="12" lg="4" class="tw-flex-col tw-justify-center tw-items-center right-side">
    <v-col sm="12" class="px-1 tw-bg-white tw-h-full" style="border-radius: 10px;">

      <v-col sm="12">
        <v-date-picker no-title flat elevation="0" class="date"
                       style="border: none!important; background: #FDFFFC!important;
                          margin-top: 1rem;" full-width color="#F66B05"
                       v-model="selectedDate" header-color="#F66B05"
                       event-color="#F66B05"  />
        <div class=" tw-flex tw-px-8 tw-flex-col">
          <v-divider />

        </div>

      </v-col>

      <v-col sm="12" class="tw-p-10 ">
<!--        <v-progress-linear-->
<!--          color="#F66B05"-->
<!--          indeterminate-->
<!--          rounded-->
<!--          height="2"-->
<!--          v-if="loading"-->
<!--        ></v-progress-linear>-->
        <div class="tw-w-full tw-p-5">
          <div class="tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-between">
            <p :style="{color:'#F66B05'}" class="right-side-header">Instructors</p>
            <p  class="right-side-see-all">See All</p>
          </div>
          <div class="tw-flex tw-w-full tw-flex-col  tw-items-start tw-justify-center" >
            <div class="tw-flex tw-w-full scroll tw-flex-col">
            <div v-for="(instructor,index) in instructors" :key="index" class="tw-flex tw-flex-row tw-w-full instructor-card  tw-items-center tw-px-5">
              <img :src="instructor.image" alt="instructor image" class="tw-w-4/12 instructor-card-img">
              <div class="tw-flex tw-w-8/12 tw-justify-center tw-flex-col tw-pl-5">
                <h6 class="instructor-card-header">{{instructor.name}}</h6>
                <label class="instructor-card-desc tw-mt-1">{{instructor.team}}</label>
              </div>
            </div>
            </div>
          </div>
<!--          <p v-else :style="{color:'#F66B05'}" class="right-side-header scroll">No Instructors Yet</p>-->
        </div>
      </v-col>
    </v-col>
  </v-col>

</div>
</template>

<script>
import IconAndText from "@/components/reuseables/IconAndText";
import applicant from "@/assets/applicants.svg"
import accepted from "@/assets/accepted.svg"
import coaches from "@/assets/coaches.svg"
import volunteers from "@/assets/volunteers.svg"
import tee from "@/assets/tee.png"
import PopularityChart from "@/components/reuseables/PopularityChart";
import AnalyticChart from "@/components/reuseables/AnalyticChart";
import {
  getTotalAcceptedApplicants,
  getTotalApplicants,
  getTotalInstructors,
  getTotalVolunteers
} from "@/services/api/APIService";


export default {
  name: "MainDashboard",
  components: {  AnalyticChart, PopularityChart, IconAndText },
  data(){
    return{
      applicant,
      accepted,
      coaches,
      volunteers,
      tee,
      selectedDate: null,
      instructors:[

      ],
      totalInstructors:0,
      totalVolunteers:0,
      totalApplicants:0,
      totalAcceptedApplicants:0
    }
  },

  methods:{
   async getTotalAcceptedApplicants(){

     await getTotalAcceptedApplicants().then(res =>{

       this.totalAcceptedApplicants = res.data
     })
       .catch((err) => {
         this.loading = false;
         this.$displaySnackbar({
           message: err.response.data.details[0],
           success: false,
         })})
    },
    async getTotalInstructors(){
      await getTotalInstructors().then(res =>{
        this.totalInstructors = res.data
      })
        .catch((err) => {
          this.loading = false;

          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          })})
    },
    async getTotalVolunteers(){
      await getTotalVolunteers().then(res =>{
        this.totalVolunteers = res.data
      })
        .catch((err) => {
          this.loading = false;

          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          })})
    },
    async getAllApplicants(){
      await getTotalApplicants().then(res =>{
        this.totalApplicants = res.data
      })
        .catch((err) => {
          this.loading = false;
          this.$displaySnackbar({
            message: err.response.data.details[0],
            success: false,
          })})
    }
  },
  async created() {
    await this.getTotalInstructors()
    await this.getTotalVolunteers()
    await this.getTotalAcceptedApplicants()
    await this.getAllApplicants()
  }
};
</script>

<style scoped lang="scss">
.right-side{
  padding: 0 0 0 2rem;
  min-height: 82vh;
  @media screen and (max-width: 1024px) {
    padding: 0;
    margin-top: 2rem;
  }
}

.right-side-see-all{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  color: #C4C4C4;
}

.right-side-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
}

.instructor-card{
  background: #FFFFFF;
  box-shadow: 0 2px 20px rgba(79, 79, 79, 0.1);
  border-radius: 10px;
  min-height: 61px;
  margin-top: 2rem;
}

.instructor-card-img{
  height: 30px;
  width: 30px;
  border-radius: 5px;
}

.instructor-card-header{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
}

.instructor-card-desc{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 8px;
  line-height: 10px;
  color: #4F4F4F;
}

.scroll {
   display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 30vh;
    height: 30vh;

  @media screen and(max-width: 380px) {
    max-height: 20vh;
    height: 20vh;
  }
}
.scroll::-webkit-scrollbar {

    background: transparent !important;
    width: 5px;
    height: 8px;

}
.scroll::-webkit-scrollbar-thumb {

    background: #c4c4c4 !important;
    //max-height: 20px !important;
    border-radius: 6px;

}
</style>