<template>
<div class="tw-flex tw-flex-col tw-p-5" style="border-radius: 10px; background: #FDFFFC;" :style="{width: width}">
<div class="tw-flex tw-flex-row tw-justify-between tw-p-2">
  <label class="title" :style="{color:companyColor}">{{title}}</label>

</div>
  <div class="tw-flex tw-w-full ">
    <apexchart type="donut" class="apex-style" :options="chartOptions" :series="series"  />
  </div>
</div>
</template>

<script>

export default {
  name: "AnalyticChart",
  components: {},
  props:{
    seriesData:[],
    xAxisData:[],
    width : [String],
    companyColor :{
      type : [String],
      default: "#004AAD"
    },
    title : [String],

  },
  data(){
    return {
      selectedFilter: "",
      series: [20, 20, 50, 10],

      chartOptions: {
        chart: {
          type: 'donut',
          fontFamily : 'Inter, serif',
          width:200
        },
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
              }
            }
          }
          },
        labels: ['20% Free Throw', '20% 3 Points', '50% of Field Goals', '10% of Dunks'],
        dataLabels: {
          enabled: false
        },
        colors: ['#D0228E','#004AAD','#C4C4C4','#7CB0F6'],
        responsive: [{
          breakpoint: 480,
          options: {
            chart: {
              width: 300
            },
            legend: {
              position: 'bottom'
            }
          }
        }]
      },
    }
  },

}
</script>

<style scoped lang="scss">
.title{
  font-family: Inter,serif !important;
  font-style: normal;
  font-weight: 500;
  font-size: 16px !important;
  line-height: 19px;
}

.apex-style{
  width: 100% !important;
  height: available !important;
  color: #19A2C7!important;
}
</style>